body {
  color: #444;
}

#app {
  min-height: 100vh;
}

.pass-switch {
  position: relative;
  float: right;
  margin-top: -35px;
  margin-right: 15px;
  font-size: 0.9rem;
  color: rgba(50, 50, 50, 0.5);
  z-index: 100;
}
.pass-switch:hover {
  cursor: pointer;
  color: rgba(50, 50, 50, 1);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
